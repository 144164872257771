@import '../../styles/variables.scss';

.HomeBanner :global {
  background-image: linear-gradient(292deg, #5776f2, #362ae4);
  padding: 200px 0 0;

  .bannerContent {
    .title {
      color: #FFF;
      font-size: 48px;
      font-weight: 700;
      max-width: 500px;
      line-height: 1.31;
    }
  
    .subtitle {
      max-width: 398px;
      font-size: 24px;
      color: #FFF;
      font-weight: 300;
      line-height: 1.33;
  
      b {
        font-weight: 700;
      }
    }
  
    .cta {
      margin-top: 20px;
      font-size: 16px;
      height: 47px;
    }
  
    .link {
      display: block;
      color: #FFF;
      font-weight: 300;
      margin-top: 20px;
  
      a {
        color: $secondaryColor;
        font-weight: 500;
        font-size: 14px;
        text-decoration: underline;
      }
    }
  
    .bannerImg {
      float: right;
    }
  }

  .testimonials {
    margin-top: 50px;

    .item {
      display: flex;
      background-color: #FFF;
      padding: 30px 16px;

      .image {
        width: 121px;
        height: 121px;
        overflow: hidden;
        border-radius: 100%;
        
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .content {
        width: calc(100% - 121px);
        padding-left: 15px;

        .title {
          color: #393939;
          font-size: 24px;
          line-height: 1.33;
          font-weight: 500;
        }

        .text {
          color: #393939;
          line-height: 1.36;
          font-size: 14px;
          margin: 0;
        }
      }
    }
  }
}
