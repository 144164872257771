.RegisterForm :global {
  padding: 50px 0;

  .form {
    width: 800px;
    max-width: 100%;
    margin:0 auto;

    .title {
      font-size: 18px;
      color: #464646;
      display: block;
      text-align: center;
      margin-bottom: 20px;
    }

    .item {
      margin-bottom: 20px;

      &.center {
        text-align: center;
      }

      &.birthdate {
        display: flex;
        flex-wrap: wrap;
      }

      .label {
        font-size: 14px;
        color: #464646;
        display: block;
        margin-bottom: 5px;
        width: 100%;
      }

      input, .ant-select-selection, .ant-select-selection__rendered, .ant-select-selection-selected-value {
        font-size: 14px;
      }

      a:hover {
        text-decoration: underline;
      }

      .btn {
        height: 50px;
        width: 200px;
      }
    }
  }
}