.PlansGrid :global {
  padding: 10px 20px;

  .actions {
    margin-bottom: 40px;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;

    thead {
      th {
        font-size: 13px;
        font-weight: 500;
        padding: 10px 5px;
        border: none;
        background: none;

        .ant-table-column-sorter {
          display: none;
        }
      }
    }

    tbody {
      tr {
        td {
          font-size: 12px;
          color: #1f1f49;
          padding: 15px 5px;

          border: 1px solid;
          border-color: #DDD;
          border-left-width: 0;
          border-right-width: 0;
  
          &:first-child {
            border-left-width: 1px;
            border-radius: 5px 0 0 5px;
          }
  
          &:last-child {
            border-right-width: 1px;
            border-radius: 0 5px 5px 0;
          }

          .tableActions {
            .btn {
              background: none;
              border: none;
              padding: 0;
              margin-right: 15px;
              cursor: pointer;
              color: #362ae4;
              font-weight: 500;
            }
          }

          .tag {
            display: inline-block;
            border: 1px solid #acacac;
            padding: 3px 6px;
            margin-right: 5px;
            margin-bottom: 5px;
            border-radius: 5px;
          }
        }
      }
    }
  }
}