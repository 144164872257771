.CommentArea :global {
  display: flex;
  align-items: center;

  img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: 1px solid #DDD;
    margin-right: 20px;
  }

  textarea {
    min-height: 50px;
    padding: 13px 10px;
  }

  button {
    font-weight: 500;
    margin-left: 20px;
    cursor: pointer;
    height: 50px;
  }
}