@import "../../styles/variables.scss";

.HomeCourses :global {
  text-align: center;

  .title {
    color: $primaryColor;
    font-size: 48px;
    line-height: 1.31;
    max-width: 500px;
    display: table;
    margin: 0 auto;
    font-weight: 300;

    b {
      font-weight: 700;
    }
  }

  .text {
    line-height: 1.36;
    font-size: 14px;
    color: #393939;
    display: block;
    margin-top: 30px;
    max-width: 596px;
    display: table;
    margin: 20px auto;
    li {
      text-align: left;
      padding: 5px 0;
    }
  }

  .carousel {
    .page {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
      width: 100%;

      .item {
        margin: 0 10px 16px;
        width: calc(33% - 16px);
        background-color: #f5f6fd;
        display: flex;
        padding: 45px 16px;

        .image {
          width: 120px;
          height: 120px;
          border-radius: 100%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .content {
          width: calc(100% - 120px);
          padding: 0 16px;
          text-align: left;

          .courseName {
            font-size: 24px;
            color: #393939;
            font-weight: 700;
            line-height: 1;
          }

          .courseSubtitle {
            font-size: 14px;
            color: #393939;
            line-height: 1.36;
            display: block;
          }

          .infos {
            border-radius: 24px;
            background-color: #362ae4;
            color: #fff;
            font-size: 12px;
            font-weight: 300;
            padding: 2px 15px;
            display: inline-block;
            margin-top: 10px;
          }
        }
      }
    }

    .slick-dots {
      li {
        button {
          background-color: $primaryColor;
          height: 10px;
          width: 10px;
          border-radius: 100%;
          margin-top: 45px;
        }
      }
    }
  }
}
