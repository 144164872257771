.Field :global {
  margin-bottom: 20px;

  .label {
    font-size: 12px;

    &.required:after {
      content: '*';
      color: red;
    }
  }

  .input {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 4px 10px;
    height: 30px;
  }
}