.Class :global {
  padding: 30px 0;
  margin: 15px;

  .rightSidebar {
    padding-left: 30px;
  }
  
  .classTitle {
    color: #393939;
    font-size: 24px;
    margin-bottom: 20px;
  }

  .video {
    margin-bottom: 30px;
    

    video {
      width: 100%;
      height: 400px;
    }
  }

  .description {
    color: #393939;
    font-size: 14px;
    margin-bottom: 30px;
  }

  .comments {
    margin-top: 20px;
    .title {
      color: #393939;
      font-size: 24px;
      display: block;
      margin-bottom: 15px;
    }

    .list {
      // margin-top: 30px;

      .row {
        padding: 20px 0;

        & + .row {
          border-top: 1px solid #DDD;
        }
      }

      .comment {
        display: flex;
        padding: 10px;
        border-left: 3px solid #d4cd04;
        & + .comment {
          border-top: 1px solid #DDD;
        }

        .authorName {
          color: #393939;
          font-weight: 500;
          font-size: 12px;
          display: block;
          margin-bottom: 5px;
        }

        .text {
          color: #393939;
          margin-bottom: 10px;
        }
  
        .image {
          margin-right: 15px;
  
          img {
            width: 50px;
            height: 50px;
            border-radius: 100%;
            display: inline-block;
            border:1px solid #EEE;
          }
        }

        .actions {
          display: flex;

          button {
            border: none;
            background: none;
            padding: 0;
            font-size: 12px;
            font-weight: 500;
            display: inline-block;
            cursor: pointer;
          }

          .date {
            font-size: 12px;
            font-weight: 500;
            display: inline-block;

            // &:before {
            //   content: '•';
            //   margin: 0 10px;
            // }
          }
        }
      }
      
      .answers {
        padding: 10px 0 10px 65px;
        // border-top: 1px solid #DDD;
      }
    }
  }


  .markAsDone {
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 15px;
    display: flex;
    justify-content: center;

    label {
      span {
        color: #393939;
        font-size: 13px;
      }
    }
  }

  .rate {
    margin-top: 30px;
    color: rgba(0, 0, 0, 0.65);

    .label {
      display: block;
    }
  }
}