@import '../../../styles/variables.scss';

.PersonalDataForm :global {
  padding: 50px 0;

  .form {
    display: flex;
    flex-direction: column;
    width: 800px;
    max-width: 100%;
    margin:0 auto;

    .title {
      font-size: 18px;
      color: #464646;
      display: block;
      text-align: center;
      margin-bottom: 20px;
    }

    .item {
      margin-bottom: 20px;

      &.center {
        text-align: center;
      }

      &.birthdate {
        display: flex;
        flex-wrap: wrap;
      }

      .label {
        font-size: 14px;
        color: #464646;
        display: block;
        margin-bottom: 5px;
        width: 100%;
      }

      input, .ant-select-selection, .ant-select-selection__rendered, .ant-select-selection-selected-value {
        font-size: 14px;
      }

      a:hover {
        text-decoration: underline;
      }

      .btn {
        height: 50px;
        width: 200px;
      }
    }
  }

  .steps {
    width: 100%;

    ul {
      margin:0;
      padding:0;
      display: flex;

      li {
        display: inline-block;
        flex:1;
        background-color: #ededed;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 0;

        .number {
          background-color: #cccccc;
          color: #FFF;
          width: 35px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
          margin-right: 10px;
          font-size: 14px;
        }

        .title {
          color: #cccccc;
          font-size: 14px;
        }

        &.current {
          background-color: #FFF;
          border: solid 1px #cccccc;

          .number {
            background-color: $primaryColor;
          }

          .title {
            color: $primaryColor;
          }
        }
      }
    }
  }
}