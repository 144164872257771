.CourseSidebar :global {
  border-right: 1px solid #CCC;
 
  height: 100vh;

  .header {
    display: flex;
    padding: 30px 10px 30px 0;
    border-bottom: 1px solid #CCC;
    align-items: center;

    .progress {
      margin-right: 15px;
    }

    .text {
      .title {
        color: #393939;
        font-weight: bold;
      }
    }
  }

  .list {
    .item {
      border-bottom: 1px solid #DDD;
      padding: 10px 0;

      .name {
        display: flex;
        align-items: center;
        padding: 5px;
        cursor: pointer;

        button {
          background: none;
          padding: 0;
          border: none;
          font-size: 24px;
          margin-right: 20px;
          outline: none;
          position: relative;

          &.close {
            &:before {
              content: '-';
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }

        .title {
          padding: 5px 0;
          color: #393939;
          font-weight: 500;
        }
      }

      .options {
        width: 100%;
        padding: 0;
        margin: 0;

        li {
          display: flex;
          align-items: center;
          padding: 5px;
          color: #393939;
          font-weight: 500;
          cursor: pointer;

          &:hover,
          &.active {
            background-color: #DDD;
          }

          &:before {
            content: '';
            width: 23px;
            height: 23px;
            border: 1px solid #393939;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            margin-right: 20px;
            padding: 0;
          }

          &.done {
            &:before {
              content: url('https://api.iconify.design/ant-design/check-circle-filled.svg?color=lime&height=28&width=28');
              border: none;
            }
          }
        }
      }
    }
  }
}