@import "../../styles/variables.scss";

.PurchasePlanResume :global {
  padding-top: 50px;
  margin-left: 20px;
  background-color: #f5f6fd;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  strong {
    font-size: 24px;
    align-self: center;
  }
  .plan {
    .planName {
      color: $primaryColor;
      font-size: 18px;
      font-weight: 700;
    }

    .planPrice {
      color: #393939;
      font-size: 18px;
      font-weight: 700;
      display: block;
    }

    .planFeatures {
      margin: 10px 0;
      .tag {
        display: flex !important;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        list-style: none;
        display: block;
        text-align: left;
        font-size: 14px;
        color: #393939;
        margin-bottom: 5px;
        .material-symbols-outlined {
          color: green;
        }
        span + span {
          margin-left: 15px;
          text-transform: capitalize;
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    span {
      margin-bottom: 10px;
    }
  }
}
