.CourseCard :global {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  background-color: #f5f6fd;
  cursor: pointer;
  img {
    margin-right: 20px;
  }

  .infoContainer {
    padding-right: 10px;
  }

  .infoGroup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #362ae4;
    color: #fff;
    width: fit-content;
    padding: 5px 10px;
    border-radius: 30px;
    span + span {
      margin-left: 5px;
    }
  }
  .name {
    font-size: 22px;
    font-weight: bold;
    line-height: 1;
    color: #393939;
    display: block;
    margin-bottom: 10px;
    text-align: left;
  }

  .description {
    font-size: 14px;
    color: #393939;
    line-height: 1.21;
    display: block;
    margin-bottom: 10px;
    text-align: left;
  }

  .flag {
    color: #fff;
    background-color: #362ae4;
    padding: 2px 15px;
    display: inline-block;
    font-weight: 300;
    font-size: 12px;
    border-radius: 25px;
  }
  .material-symbols-rounded {
    font-size: 18px;
  }
}
