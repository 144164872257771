@import "../../../styles/variables.scss";

.PaymentThanks :global {
  padding: 50px 0;
  p {
    width: 400px;
    text-align: justify;
  }
  .acessButton {
    margin-top: 30px;
  }
}
