@import '../../styles/variables.scss';

.TopBar :global, .TopBarWithBG :global{
    padding: 25px 0;
    position: fixed;
    left: 0;
    right: 0;
    // background-image: linear-gradient(292deg, #5169ef, #372ce4);
    transition: 0.3s;
    z-index: 10;

    .logo {
        width: 225px;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .accessBtns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;

        .loginBtn {
            background: none;
            color: #FFF;
            border: none;

            &:hover {
                color: $secondaryColor;
            }
        }

        button {
            margin-left: 10px;
        }
    }

    .userInfo {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        float: right;

        .image {
            margin-right: 5px;
            width: 50px;
            height: 50px;
            overflow: hidden;
            border-radius: 100%;
            border: 1px solid #CCC;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .name {
            font-size: 16px;
            color: #FFF;
            cursor: pointer;
            padding: 11px 0;

            .icon {
                margin-left: 10px;
            }
        }
    }
}