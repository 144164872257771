.ProfileForm :global {
  
  display: flex;
  justify-content: space-between;
  .box {
    border: solid 1px #707070;
    border-left-width: 7px;
    padding: 20px 30px;
    width: 40%;

    .field {
      margin: 15px 0;

      .label {
        display: block;
        font-size: 14px;
        margin-bottom: 4px;
      }
    }
  }
  
}
.saveButton {
  margin-left: auto;
  margin-top: 15px;
}
.profileColContainer {
  padding: 60px;
}