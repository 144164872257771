@import "../../styles/variables.scss";

.HomePLans :global {
  text-align: center;
  padding: 70px 0;

  .sectionTitle {
    color: $primaryColor;
    font-size: 48px;
    line-height: 1.31;
    max-width: 500px;
    display: table;
    margin: 0 auto;
    font-weight: 300;

    b {
      font-weight: 700;
    }
  }

  .sectionText {
    line-height: 1.36;
    font-size: 14px;
    color: #393939;
    display: block;
    margin-top: 30px;
    max-width: 596px;
    display: table;
    margin: 20px auto;
    p {
      text-align: justify !important;
    }
  }

  .list {
    display: flex;

    .plan {
      width: calc(100% / 3);
      background-color: #f5f6fd;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;

      &:nth-child(2) {
        box-shadow: 0px 0px 13px 7px #dadada;
        z-index: 2;
        border-top: 8px solid $primaryColor;
      }

      .planImage {
        margin-bottom: 10px;
      }

      .planName {
        color: $primaryColor;
        font-size: 24px;
        font-weight: 700;
      }

      .planPrice {
        color: #393939;
        font-size: 24px;
        font-weight: 700;
        display: block;
        margin: 20px 0;
      }

      .planDescription {
        font-size: 14px;
        color: #393939;
      }

      .planFeatures {
        margin: 30px 0;
        .tag {
          display: flex !important;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          list-style: none;
          display: block;
          text-align: left;
          font-size: 14px;
          color: #393939;
          margin-bottom: 5px;
          .material-symbols-outlined {
            color: green;
          }
          span + span {
            margin-left: 15px;
            text-transform: capitalize;
          }
        }
      }
    }
  }
}
