@import "../../styles/variables.scss";

.Footer :global {
  .contactForm {
    padding: 50px 0 0;
    background-color: #362ae4;
    color: #fff;

    .sectionTitle {
      font-size: 48px;
      display: block;
      text-align: center;
      margin-bottom: 50px;
    }

    .content {
      .title {
        font-size: 24px;
        margin-bottom: 10px;
        display: block;
      }

      p {
        font-size: 14px;
        line-height: 1.36;
      }
    }

    .form {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .item {
        margin-bottom: 25px;
        width: 50%;
        .label {
          display: block;
          margin-bottom: 7px;
        }
        
        input {
          background: none;
          color: #fff;
          padding: 20px 10px;
        }

        textarea {
          background: none;
          color: #fff;
          padding: 10px;
          width: 100%;
          height: 200px;
        }
      }

      .sendBtn {
        float: right;
      }
    }

    .infos {
      width: 100%;
      display: flex;
      margin-top: 40px;
      margin-bottom: -50px;

      .item {
        background-color: #161616;
        color: #fff;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        a {
          color: #fff;
        }
        a :visited {
          color: #888;
        }
        &:nth-child(2) {
          background-color: #2c2c33;
        }

        span {
          margin-left: 20px;
        }
      }
    }
  }

  .newsletter {
    background-color: #525357;
    padding: 100px 0 30px;

    .sectionTitle {
      color: #fff;
      text-align: center;
      font-size: 24px;
      display: block;
      font-weight: 700;
    }

    .sectionSubtitle {
      display: block;
      color: #fff;
      text-align: center;
      margin: 10px 0;
    }

    .form {
      display: flex;
      padding: 20px 20%;

      input {
        flex: 3;
        height: 70px;
        font-size: 17px;
        padding: 0 20px;
        border-radius: 4px 0 0 4px;
      }

      button {
        flex: 1;
        background-color: $primaryColor;
        border: none;
        color: #fff;
        font-size: 17px;
        border-radius: 0 4px 4px 0;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          background-color: $secondaryColor;
          color: #000;
        }
      }
    }

    .networks {
      margin: 30px 0 15px;

      ul {
        text-align: center;
        padding: 0;

        li {
          list-style: none;
          font-size: 15px;
          width: 20px;
          height: 20px;
          display: inline-block;
          margin: 0 6px;

          a {
            color: #fff;

            &:hover {
              color: $secondaryColor;
            }
          }

          svg {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }

    .copyright {
      display: block;
      text-align: center;
      color: #fff;
    }
  }
}
