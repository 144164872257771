@import '../../styles/variables.scss';

.HomeAbout :global {
  width: 100%;
  padding: 70px 0;
  text-align: center;

  .title {
    color: $primaryColor;
    font-size: 48px;
    line-height: 1.31;
    max-width: 500px;
    display: table;
    margin: 0 auto;
    font-weight: 300;

    b {
      font-weight: 700;
    }
  }
  .text {
    line-height: 1.36;
    font-size: 14px;
    color: #393939;
    padding: 0 40px;
    display: block;
    margin-top: 30px;
    p {
      text-align: justify;
    }
  }

  .banner {
    width: 100%;
    margin-top: 30px;
  }
}
