.CourseProgression :global {
  padding: 50px;
  .material-symbols-rounded {
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
  }
  .title {
    color: #362ae4;
    font-size: 38px;
    width: 100%;
    text-align: center;
    font-weight: 700;
  }
  
  .courseName {
    display: block;
    font-size:30px;
    color: #3b2ef0;
  }
  .courseDescription {
    display: block;
    margin-bottom: 10px;
  }
  .courseList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
  }
  .info-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .infoGerais {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
    margin: 20px 0;
  }
  .classesGrid {
    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 8px;

      thead {
        th {
          font-size: 13px;
          font-weight: 500;
          padding: 10px 5px;
          border: none;
          background: none;

          .ant-table-column-sorter {
            display: none;
          }
        }
      }
  
      tbody {
        tr {
          td {
            font-size: 12px;
            color: #1f1f49;
            padding: 15px 5px;
  
            border: 1px solid;
            border-color: #DDD;
            border-left-width: 0;
            border-right-width: 0;
    
            &:first-child {
              border-left-width: 1px;
              border-radius: 5px 0 0 5px;
            }
    
            &:last-child {
              border-right-width: 1px;
              border-radius: 0 5px 5px 0;
            }
          }
        }
      }
    }
  }
  .list {
    .classData {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      background-color: #f5f6fd;
      .name {
        font-size: 22px;
        font-weight: bold;
        line-height: 1;
        color: #393939;
        display: block;
        margin-bottom: 10px;
      }
      .description {
        font-size: 14px;
        color: #393939;
        line-height: 1.21;
        display: block;
        margin-bottom: 10px;
      }
    }
  }
  .noCourse {
    margin: auto;
    font-size: 22px;
    font-weight: bold;
    line-height: 1;
    color: #393939;
    display: block;
  }
}