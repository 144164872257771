.ClassTest :global {
  padding: 30px;

  .testTitle {
    color: #393939;
    font-size: 24px;
    margin-bottom: 20px;
  }

  .testDescription {
    color: #393939;
  }

  .questions {
    padding: 20px 0;

    .item {
      display: flex;
      align-items: flex-start;

      & + .item {
        margin-top: 30px;
      }

      .number {
        font-size: 14px;
        background: #bbbbbb;
        color: #393939;
        width: 35px;
        height: 35px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .content {
        padding: 7px 15px;

        .title {
          margin-bottom: 10px;
          display: block;
        }
      }
    }
  }

  .finishBtn {
    background: #393939;
    color: #FFF;
    border-color: #393939;
    margin-top: 30px;
  }
}

.Template :global {
  padding: 30px;

  .testTitle {
    color: #393939;
    font-size: 24px;
    margin-bottom: 20px;
  }

  .testDescription {
    color: #393939;
  }

  .resultPhrase {
    color: #393939;
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .questions {
    padding: 0;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 25px;
      top: 10px;
      bottom: 10px;
      width: 1px;
      background-color: #bbbbbb;
    }

    li {
      list-style: none;
      display: flex;
      padding-left: 5px;

      & + li {
        margin-top: 40px;
      }

      .number {
        background-color: #bbbbbb;
        width: 40px;
        height: 40px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        margin-right: 10px;
        color: #000;
        border: 4px solid #FFF;

        .result {
          position: absolute;
          left: -7px;
          top: 0;

          .icon {
            width: 16px;
            height: 16px;
            background-color: #FFF;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 1;
            border-radius: 100%;

            &.correct {
              color: #40d36c;
            }
            
            &.incorrect {
              color: #f5222d;
            }
          }
        }
      }

      .content {
        margin-top: 10px;

        .questionTitle {
          font-weight: 500;
          color: #393939;
        }

        .result {
          display: block;
          font-size: 14px;
          font-weight: 500;

          &.correct {
            color: #40d36c;
          }
          
          &.incorrect {
            color: #f5222d;
          }
        }
      }
    }
  }

  .btn {
    background: #393939;
    color: #FFF;
    border-color: #393939;
    margin-top: 30px;
  }
}