@import "../../styles/variables.scss";

.PurchasePlanForm :global {
  padding: 50px 0;

  .steps-action {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
  }

  .steps {
    display: flex;
    align-items: center;
    justify-content: center;

    ul {
      margin: 0;
      padding: 0;
      display: flex;

      li {
        display: inline-block;
        flex: 1;
        background-color: #ededed;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 0;

        .number {
          background-color: #cccccc;
          color: #fff;
          width: 35px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
          margin-right: 10px;
          font-size: 14px;
        }

        .title {
          color: #cccccc;
          font-size: 14px;
        }

        &.current {
          background-color: #fff;
          border: solid 1px #cccccc;

          .number {
            background-color: $primaryColor;
          }

          .title {
            color: $primaryColor;
          }
        }
      }
    }
  }
}
