.LoginPage :global {
  display: flex;
  align-items: center;
  padding: 70px 0;

  .box {
    width: 100%;
    padding: 40px;
    background-color: #eeeeee;

    .title {
      display: block;
      text-align: center;
      font-size: 18px;
      color: #362ae4;
      font-weight: 700;
    }

    .register{
      display: block;
      text-align: center;
    }

    .subtitle {
      display: block;
      text-align: center;
      margin-bottom: 35px;
      color: #262626;
      font-size: 14px;
    }

    .form {
      .label {
        color: #262626;
        font-size: 14px;
        margin-bottom: 5px;
        display: block;

        &.required {
          &:after {
            content: '*';
            color: #FF0000;
          }
        }
      }

      .field {
        margin-bottom: 20px;
        background-color: #FFF;
        height: 38px;
      }
    }


    .btn {
      width: 100%;
      margin-bottom: 10px;
      background-color: #362ae4;
      border-color: #362ae4;
      color: #FFF;
    }
  }
}