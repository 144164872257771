.UserProgression :global {
  padding: 50px;
  .material-symbols-rounded {
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
  }
  .title {
    color: #362ae4;
    font-size: 38px;
    width: 100%;
    text-align: center;
    font-weight: 700;
  }

  .info-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .packageName {
    display: block;
    font-size:30px;
    color: #3b2ef0;
  }
  .packageDescription{
    display: block;
    margin-bottom: 10px;
  }
  .packageList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
  }
  .list {
    .course {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      background-color: #f5f6fd;
      cursor: pointer;
      // width: calc((100% / 4) - 20px);
      img {
        margin-right: 20px;
      }
      
      .infoGroup{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #362ae4;
        color: #FFF;
        width: fit-content;
        padding: 5px 10px;
        border-radius: 30px;
        span + span {
          margin-left: 5px;
        }
      }
      .name {
        font-size: 22px;
        font-weight: bold;
        line-height: 1;
        color: #393939;
        display: block;
        margin-bottom: 10px;
      }

      .description {
        font-size: 14px;
        color: #393939;
        line-height: 1.21;
        display: block;
        margin-bottom: 10px;
      }

      .flag {
        color: #FFF;
        background-color: #362ae4;
        padding: 2px 15px;
        display: inline-block;
        font-weight: 300;
        font-size: 12px;
        border-radius: 25px;
      }
    }
  }
  .noCourse {
    margin: auto;
    font-size: 22px;
    font-weight: bold;
    line-height: 1;
    color: #393939;
    display: block;
  }
}